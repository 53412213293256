"use strict";

var formValidation = require("base/components/formValidation");

const setupRegretForm = () => {
    // submit form
    $("#regret-form").submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr("action");
        var data = form.serialize();
        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: data,
            success: function (data) {
                if (data.success) {
                    $(".js-submit-feedback").text(data.message);
                    $(".js-submit-feedback").toggleClass("d-none");
                } else {
                    formValidation(form, data);
                }
            }
        });
    });
};

const initRegretForm = () => {
    setupRegretForm();
};

export default initRegretForm;
