"use strict";

import { Swiper, Navigation, Pagination, Scrollbar } from "swiper";
import breakpoints from "../helpers/breakpoints";

const defaultSlidesToShow       = 5;
const defaultSlidesToShowMobile = 2;
const defaultSpacingInPixels    = 16;

/**
 * Initializes a carousel using Swiper library
 * @param {jQuery} $swiperWrapper - jQuery object representing the carousel wrapper element
 * @returns {Swiper} - the initialized Swiper instance
 */
export const initializeCarousel = ($swiperWrapper) => {
    const swipperWrapperSelector = `.js-swiper-carousel[data-id="${$swiperWrapper.data("id")}"]`;

    let slidesToShow, slidesToShowMobile, spacing;

    try {
        slidesToShow       = parseInt($swiperWrapper.data("slides-to-show"), 10) || defaultSlidesToShow;
        slidesToShowMobile = parseInt($swiperWrapper.data("slides-to-show-mobile"), 10) || defaultSlidesToShowMobile;
        spacing            = parseInt($swiperWrapper.data("spacing"), 10) || defaultSpacingInPixels;
    } catch (e) {
        slidesToShow       = defaultSlidesToShow;
        slidesToShowMobile = defaultSlidesToShowMobile;
        spacing            = defaultSpacingInPixels;
    }

    const loopEnabled       = $swiperWrapper.data("loop-enabled");
    const navEnabled        = $swiperWrapper.data("navigation-enabled");
    const paginationEnabled = $swiperWrapper.data("pagination-enabled");
    const scrollbarEnabled  = $swiperWrapper.data("scroll-enabled");
    const direction         = $swiperWrapper.data("direction");
    const clickableSlide    = !!$swiperWrapper.data("clickable-slide");
    const carouselModules   = [];

    if (navEnabled) {
        carouselModules.push(Navigation);
    }

    if (paginationEnabled) {
        carouselModules.push(Pagination);
    }

    if (scrollbarEnabled) {
        carouselModules.push(Scrollbar);
    }

    return new Swiper(`${swipperWrapperSelector} .js-swiper-container`, {
        loop: Boolean(loopEnabled),
        modules: carouselModules,
        direction: direction || "horizontal",
        watchSlidesProgress: true,
        pagination: {
            el: `${swipperWrapperSelector} .js-swiper-pagination`,
            type: "bullets",
            clickable: true,
        },
        navigation: {
            nextEl: `${swipperWrapperSelector} .js-swiper-button-next`,
            prevEl: `${swipperWrapperSelector} .js-swiper-button-prev`,
        },
        scrollbar: {
            el: `${swipperWrapperSelector} .js-swiper-scrollbar`,
        },
        slideToClickedSlide: clickableSlide,
        slidesPerView: slidesToShowMobile,
        spaceBetween: spacing,
        breakpoints: {
            [breakpoints.md]: {
                slidesPerView: slidesToShow,
                spaceBetween: spacing,
            },
            [breakpoints.sm]: {
                slidesPerView: 1,
                spaceBetween: spacing,
                centeredSlides: true
            }
        },
    });
};

const setupSwiperCarouselConfiguration = () => {
    const carousels = $(".js-swiper-carousel");

    carousels.each(function () {
        const $swiperWrapper = $(this);

        initializeCarousel($swiperWrapper);
    });
};

const initSwiperCarousels = () => {
    setupSwiperCarouselConfiguration();
};

export default initSwiperCarousels;
