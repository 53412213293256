import { Swiper, Autoplay, Navigation } from "swiper";

const initializeSwiperCarousel = () => {
    if (!window.swiperCarouselInitialized) {
        const carousels = $(".swiper-carousel-glide");

        carousels.each((index, carousel) => {
            const selector = "." + $(carousel).data().id + " .swiper";
            const timer    = $(carousel).data().timer;
            const slidesPerView    = $(carousel).data().view;

            const firstBanner = $(carousel).find(".banner").get(0);
            const firstImage  = $(firstBanner).find("img").get(0);

            $(firstImage).attr("loading", "eager");

            new Swiper(selector, {
                loop: false,
                autoplay: {
                    delay: +timer,
                    pauseOnMouseEnter: true
                },
                lazyPreloadPrevNext: 1,
                modules: [Autoplay, Navigation],
                parallax: true,
                navigation: {
                    enabled: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: slidesPerView,
                        spaceBetween: 30
                    }
                },
            });
        });

        window.swiperCarouselInitialized = true;
    }
};

export default initializeSwiperCarousel;
