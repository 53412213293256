/**
* Animates the scroll of the page to a specific element with an optional offset and duration.
*
* @param {jQuery} element - The jQuery object representing the target element to scroll to.
* @param {number} offset - The offset to subtract from the element's top position.
* @param {number} [time=500] - The duration of the scroll animation in milliseconds.
*
* @returns {void}
*/
"use strict";

module.exports = function (element, offset, time = 500) {
    var position = element && element.length ? element.offset().top : 0;
    if (offset) {
        position -= offset;
    }

    $("html, body").animate({
        scrollTop: position
    }, time);

    if (!element) {
        $(".logo-home").focus();
    }
};
