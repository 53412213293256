import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const animateBanners = () => {
    $(".banner").each(function () {
        const bannerId = $(this).attr("id");
        const bannerTitle = $(this).find("h1");
        const bannerText = $(this).find("p");

        // Animate every banner H1 Tag
        gsap.fromTo(bannerTitle,
            {
                opacity: 0,
                transform: "translateY(50px)"
            }, 
            {
                scrollTrigger: `#${bannerId}`,
                opacity: 1,
                transform: "translateY(0)",
                duration: 1,
                delay: 0.1
            }
        );

        // Animate every banner p Tag
        gsap.fromTo(bannerText, 
            {
                opacity: 0,
                transform: "translateY(50px)"
            }, 
            {
                scrollTrigger: `#${bannerId}`,
                opacity: 1,
                transform: "translateY(0)",
                duration: 1,
                delay: 0.2
            }
        );
    });
};

export {
    animateBanners
};
