const initTotalsUpdateListener = () => {
    if ($(".cart-page").length === 0) {
        return;
    }


    $("body").on("cart:update", function (e, basket) {
        if (!basket || !basket.totals) {
            return;
        }
        $(".js-true-total").html(basket.totals.subTotal);
    });
};

export default initTotalsUpdateListener;
