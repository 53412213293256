"use strict";

const scrollAnimate = require("./scrollAnimate");

/**
 * Focus on the first input error
 */
function focusOnInputError() {
    $(".form-control").on("invalid", function () {
        const firstInvalidElement = $(".form-control.is-invalid").first();
        if (firstInvalidElement.length) {
            scrollAnimate(firstInvalidElement, 200, 100);
            firstInvalidElement.focus();
        }
    });
}

export default focusOnInputError;
