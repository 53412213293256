import { animateBanners } from "./animations";
import initRegretForm from "./regretForm";
import initTotalsUpdateListener from "./totals";
import focusOnInputError from "./focusOnInputError";

const initComponents = () => {
    animateBanners();
    initRegretForm();
    initTotalsUpdateListener();
    focusOnInputError();
};

export default initComponents;
