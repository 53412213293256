"use strict";

const breakpoints = {
    xs  : 0,
    sm  : 340,
    md  : 768,
    lg  : 1024,
    xl  : 1440,
    xxl : 1600
};

export default breakpoints;

