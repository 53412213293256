import $ from "jquery";
import "plugin_wishlists/main";

import { createIcons, icons } from "lucide";

// Components
import initializeExperienceComponents from "./experience/index";
import initComponents from "./components";

$(() => {
    // Initial Ford JS Client-side code
    initializeExperienceComponents();
    initComponents();
    createIcons({ icons });
});
